<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="name || '-'"
      :section="'Edit Profile'"
    />

    <customer-corporate-form :action="'edit'" :company="company" />
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import * as actions from "@/store/action-types";
import CustomerCorporateForm from "@/components/Customers/Corporate/CustomerCorporateForm.vue";

export default {
  name: "CustomerCorporateEdit",
  components: {
    CustomerCorporateForm,
  },
  data() {
    return {
      loading: false,
      company: {},
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    name() {
      return this.company.company_name;
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      corporate
        .get(this.customerId)
        .then((response) => {
          if (response.data.status) {
            this.company = { ...response.data.data };
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
