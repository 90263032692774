<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section
        title="Company Details"
        subtitle="Information about the company."
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Company name"
                prop="company_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.company_name"
                  placeholder="Enter company name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Location area"
                prop="location_area_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.location_area_id"
                  filterable
                  placeholder="Select location"
                >
                  <el-option
                    v-for="location in locations"
                    :key="location.id"
                    :label="location.name"
                    :value="location.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Address"
                prop="company_address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.company_address"
                  placeholder="Enter the company’s full office address"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Contact Person"
        subtitle="Information about Eden’s contact person at this company."
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="contact_person_first_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_person_first_name"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="contact_person_last_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_person_last_name"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Email"
                prop="contact_person_email_address"
                :rules="validateEmail()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_person_email_address"
                  placeholder="you@mail.com"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="contact_person_phone_number"
                :rules="validatePhone('Phone number')"
              >
                <eden-phone-input
                  :input.sync="form.contact_person_phone_number"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Other"
        subtitle="Information about Eden’s contact person at this company."
      >
        <template slot="form-fields">
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Customer type"
                prop="corporate_persona_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.corporate_persona_id"
                  placeholder="Select a customer type"
                >
                  <el-option
                    v-for="(persona, i) in personas"
                    :key="i"
                    :label="persona.name"
                    :value="persona.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        v-if="action === 'add'"
        title="Service Configuration"
        subtitle="Select the services the employees of this company will be getting."
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label=""
                prop="service"
                :rules="validateField('Services')"
              >
                <subscription-configuration
                  @set-config="setSubscriptionConfiguration"
                  :menu-type-variation="{ corporate: null }"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Start date"
                prop="start_date"
                :rules="validateField('Start date')"
              >
                <el-date-picker
                  v-model="form.start_date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  format="dddd, dd MMMM, yyyy"
                  prefix-icon="eden-icon-calendar"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="goHome">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="addCorporateCustomer"
              >Add Corporate Customer</el-button
            >
            <el-button
              v-else
              type="primary"
              :loading="updating"
              @click="updateCorporateCustomer"
              >Save changes</el-button
            >
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerCorporateForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      services: ["Cleaning", "Laundry", "Meal"],
      customerId: this.$route.params.id,
      form: {
        company_name: "",
        company_address: "",
        location_area_id: "",
        contact_person_first_name: "",
        contact_person_last_name: "",
        contact_person_email_address: "",
        contact_person_phone_number: "",
        corporate_persona_id: "",
        service: [],
        amount: 0,
        plan: {},
        start_date: "",
      },
      personas: [],
      loading: false,
      adding: false,
      updating: false,
    };
  },
  computed: {
    locations() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    company() {
      this.loading = true;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.company[key];
      });
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },
  },
  created() {
    this.getPersonas();
  },
  methods: {
    getPersonas() {
      corporate
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.personas = response.data.data;
          }
        })
        .catch();
    },
    goHome() {
      this.$router.push({ name: "customers.index" });
    },
    setSubscriptionConfiguration({ config, amount }) {
      this.form.service = Object.keys(config);
      this.form.plan = config;
      this.form.amount = amount;
    },
    formatForm() {
      const payload = this.parseData(this.form);
      const sortedPlan = this.sortObject(payload.plan);
      payload.plan = JSON.stringify(sortedPlan);
      payload.new_onboarding_flow = true;
      if (sortedPlan?.meal) {
        payload.menu_type_variation_id =
          sortedPlan?.meal?.menu_type_variation_id;
      }
      return payload;
    },
    addCorporateCustomer() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;
        const payload = this.formatForm();

        corporate
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$store
                .dispatch(actions.GET_CUSTOMERS_CORPORATE, 1)
                .then(() => {
                  this.adding = false;
                  this.$router.push({ name: "customers.index" });
                })
                .catch(() => {
                  this.adding = false;
                  this.$router.push({ name: "customers.index" });
                });
            } else {
              this.adding = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    updateCorporateCustomer() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = this.formatForm();

        delete payload.service;
        delete payload.plan;
        delete payload.start_date;
        delete payload.amount;

        corporate
          .update(this.company.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$router.push({
                name: "customers.index",
              });
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
